import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PasswordForgotRequest } from '@models/users/dto/forgot-password.request';
import { PasswordResetRequest } from '@models/users/dto/password-reset.request';
import { ResendEmailCodeRequest } from '@models/users/dto/resend-email-code.request';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  lastValueFrom,
  throwError,
} from 'rxjs';
import { User } from '@models/users/user.model';
import { environment as env } from '@environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { catchError, map, tap } from 'rxjs/operators';
import { AppRoutes } from '../../routes';
import { UserRole, isAdmin, isCustomer } from '@models/users/user-role.enum';
import { UserVerificationCodeType } from '@models/sign-up/user-verification-code-type.enum';
import { UpdateUserRequest } from '@models/users/dto/update-user.request';
import { LoginRequest } from '@models/users/dto/login.request';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly localStorageKeyUser = 'user';
  private readonly cookieServiceKey = 'eventpageai';
  private readonly eddyUserCookieServiceKey = 'eventpageai_user';

  private token: string | null = null;
  private auth_headers: HttpHeaders | null = null;
  destinationUrl: string | null = null;
  // homeUrl: string;
  operator: User | null = null;

  userSubject = new ReplaySubject<User>(1);

  isAuthenticatedSubject = new BehaviorSubject<boolean>(!!this.getUser().role);
  private _currentUserId?: number;
  //   private readonly isServer: boolean;

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService, // @Optional() @Inject(REQUEST) private request, // @Inject(PLATFORM_ID) platformId
  ) {
    this.refreshTheLoggedUserData();
    this.userSubject.next(this.getUser());
    // this.isServer = isPlatformServer(platformId);
  }

  public refreshTheLoggedUserData() {
    const user = this.getUser();
    if (!user.id) {
      return;
    }

    if (!this.authTokenExists()) {
      return;
    }

    this.loadUser().subscribe();
  }

  public async refreshTheLoggedUserDataAsync(): Promise<User | undefined> {
    const user = this.getUser();
    if (!user.id) {
      return;
    }

    if (!this.authTokenExists()) {
      return;
    }

    return await lastValueFrom(this.loadUser());
  }

  private authTokenExists() {
    return (
      (this.auth_headers && this.auth_headers.get('Authorization')) ||
      !!this.cookieService.get(this.cookieServiceKey)
    );
  }

  get currentUserId(): number | undefined {
    if (this._currentUserId) {
      return this._currentUserId;
    }

    const localStorageUser = localStorage.getItem(this.localStorageKeyUser);
    if (localStorageUser) {
      const user = JSON.parse(localStorageUser);
      return user.id;
    }

    return undefined;
  }

  getUser(): User {
    // if (this.isServer) {
    //   let user: string;
    //   const rawCookies = !!this.request.headers['cookie']
    //     ? this.request.headers['cookie']
    //     : '';
    //   if (rawCookies.indexOf(this.eddyUserCookieServiceKey) !== -1) {
    //     const userKeySubstring = rawCookies.substring(
    //       rawCookies.indexOf(this.eddyUserCookieServiceKey) +
    //         (this.eddyUserCookieServiceKey.length + 1)
    //     );
    //     if (userKeySubstring.indexOf(';') !== -1) {
    //       user = userKeySubstring.substring(0, userKeySubstring.indexOf(';'));
    //     } else {
    //       user = userKeySubstring;
    //     }
    //   }

    //   // check if user is authenticated and user object is already in cookies
    //   if (this.getHeaders() !== null && user) {
    //     const decodedUser = decodeURIComponent(user);
    //     const loggedUser = new UserInfo(JSON.parse(decodedUser));
    //     this.userSubject.next(loggedUser);
    //     this._currentUserId = loggedUser.id;
    //     if (isTeamMemberUserRole(loggedUser.role)) {
    //       this.reloadLoggedTeamMember().subscribe();
    //     } else if (isLearner(loggedUser.role) && this.getLearner() === null) {
    //       this.loadLearner().subscribe();
    //     }
    //     return loggedUser;
    //   }
    // } else {
    //   // check if user is authenticated and user object is already in localStorage
    //   if (
    //     this.getHeaders() !== null &&
    //     localStorage.getItem(this.localStorageKeyUser) !== null
    //   ) {
    //     return new UserInfo(
    //       JSON.parse(localStorage.getItem(this.localStorageKeyUser))
    //     );
    //   }
    // }

    // check if user is authenticated and user object is already in localStorage
    const localStorageUser = localStorage.getItem(this.localStorageKeyUser);
    if (this.getHeaders() !== null && localStorageUser !== null) {
      return new User(JSON.parse(localStorageUser));
    }

    // if there is no user object in localStorage,
    // return an empty dummy user object to prevent errors
    return new User({
      id: 0,
      uuid: '',
      email: '',
      emailVerified: true,
      role: UserRole.GUEST,
      name: '',
      phone: '',
      phoneVerified: false,
      avatarUrl: '',
      gender: '',
      description: '',
      language: '',
      platform: '',
      isB2B: false,
    });
  }

  getToken(): string {
    return this.cookieService.get(this.cookieServiceKey);
  }

  getHeaders() {
    if (this.auth_headers && this.auth_headers.get('Authorization')) {
      return this.auth_headers;
    }

    let token = this.cookieService.get(this.cookieServiceKey);
    // if (this.isServer) {
    //   const rawCookies = !!this.request.headers['cookie']
    //     ? this.request.headers['cookie']
    //     : '';
    //   if (rawCookies.indexOf(this.cookieServiceKey) !== -1) {
    //     const authKeySubstring = rawCookies.substring(
    //       rawCookies.indexOf(this.cookieServiceKey) +
    //         (this.cookieServiceKey.length + 1)
    //     );
    //     if (authKeySubstring.indexOf(';') !== -1) {
    //       token = authKeySubstring.substring(0, authKeySubstring.indexOf(';'));
    //     } else {
    //       token = authKeySubstring;
    //     }
    //   }
    // }

    if (token) {
      const headers = new HttpHeaders();
      this.auth_headers = headers.append('Authorization', 'Bearer ' + token);
      this.token = token;
      return this.auth_headers;
    }
    return null;
  }

  getHttpOptions(
    _parameters: Map<string, string> | null = null,
    addAuthorizationHeader: boolean = true,
    responseType?: string,
  ): any {
    let params = new HttpParams(); // .set('key', env.googleApiKey);

    if (_parameters) {
      _parameters.forEach(function (value, key) {
        params = params.set(key, value);
      });
    }

    return {
      params: params,
      headers: addAuthorizationHeader ? this.getHeaders() : null,
      responseType: responseType,
    };
  }

  registerToken(_token: string) {
    this.cookieService.delete(this.cookieServiceKey, '/');

    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 1);

    this.cookieService.set(this.cookieServiceKey, _token, expiration, '/');
    this.token = _token;
  }

  registerUserCookie(user: User) {
    this.cookieService.delete(this.eddyUserCookieServiceKey, '/');

    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 1);

    this.cookieService.set(
      this.eddyUserCookieServiceKey,
      JSON.stringify({ id: user.id, role: user.role }),
      expiration,
      '/',
    );
  }

  loginAtPublish(
    loginReq: LoginRequest,
    redirect: boolean,
    granted: Function,
    denied: Function,
  ) {
    const params = new HttpParams(); // .set('key', env.googleApiKey);
    return this.http
      .post(env.api + '/login-otp', loginReq, { params: params })
      .pipe(
        map((_response: any) => {
          this.token = _response.data['token'];
          this.operator = _response.data['user'];
          if (this.operator) {
            this._currentUserId = this.operator.id;
            localStorage.setItem('user', JSON.stringify(this.operator));
          }

          let headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'application/json');
          this.auth_headers = headers.append(
            'Authorization',
            'Bearer ' + this.token,
          );

          if (this.token) {
            this.registerToken(this.token.toString());
          }

          this.isAuthenticatedSubject.next(true);
          const user = this.getUser();
          this.userSubject.next(user);

          this.registerUserCookie(user);

          granted(this.operator);
          return this.operator;
        }),
      )
      .subscribe({
        next: async () => {
          await lastValueFrom(this.loadUser());

          if (redirect && this.homeUrl) {
            if (this.destinationUrl) {
              this.router
                .navigate([this.destinationUrl.split('?')[0]], {
                  queryParams: this.extractQueryParams(this.destinationUrl),
                })
                .then();
            } else {
              const extras = {};
              this.router.navigate([this.homeUrl], extras).then();
            }
            this.destinationUrl = null;
          }
        },
        error: (_error) => {
          denied(_error.error);
          if (redirect) {
            this.router.navigate([AppRoutes.Root.login]).then();
          }
          throwError(() => _error?.error || _error);
        },
      });
  }

  loginWithOtp(
    loginReq: LoginRequest,
    redirect: boolean,
    granted: Function,
    denied: Function,
  ) {
    const params = new HttpParams(); // .set('key', env.googleApiKey);
    return this.http
      .post(env.api + '/login-otp', loginReq, { params: params })
      .pipe(
        map((_response: any) => {
          this.token = _response.data['token'];
          this.operator = _response.data['user'];
          if (this.operator) {
            this._currentUserId = this.operator.id;
            localStorage.setItem('user', JSON.stringify(this.operator));
          }

          let headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'application/json');
          this.auth_headers = headers.append(
            'Authorization',
            'Bearer ' + this.token,
          );

          if (this.token) {
            this.registerToken(this.token.toString());
          }

          this.isAuthenticatedSubject.next(true);
          const user = this.getUser();
          this.userSubject.next(user);

          this.registerUserCookie(user);

          granted(this.operator);
          return this.operator;
        }),
      )
      .subscribe({
        next: async () => {
          await lastValueFrom(this.loadUser());

          if (redirect && this.homeUrl) {
            if (this.destinationUrl) {
              this.router
                .navigate([this.destinationUrl.split('?')[0]], {
                  queryParams: this.extractQueryParams(this.destinationUrl),
                })
                .then();
            } else {
              const extras = {};
              this.router.navigate([this.homeUrl], extras).then();
            }
            this.destinationUrl = null;
          }
        },
        error: (_error) => {
          denied(_error.error);
          if (redirect) {
            this.router.navigate([AppRoutes.Root.login]).then();
          }
          throwError(() => _error?.error || _error);
        },
      });
  }

  login(
    loginReq: LoginRequest,
    redirect: boolean,
    granted: Function,
    denied: Function,
  ) {
    const params = new HttpParams(); // .set('key', env.googleApiKey);
    return this.http
      .post(env.api + '/login', loginReq, { params: params })
      .pipe(
        map((_response: any) => {
          this.token = _response.data['token'];
          this.operator = _response.data['user'];
          if (this.operator) {
            this._currentUserId = this.operator.id;
            localStorage.setItem('user', JSON.stringify(this.operator));
          }

          let headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'application/json');
          this.auth_headers = headers.append(
            'Authorization',
            'Bearer ' + this.token,
          );

          if (this.token) {
            this.registerToken(this.token.toString());
          }

          this.isAuthenticatedSubject.next(true);
          const user = this.getUser();
          this.userSubject.next(user);

          this.registerUserCookie(user);

          granted(this.operator);
          return this.operator;
        }),
      )
      .subscribe({
        next: async () => {
          await lastValueFrom(this.loadUser());

          if (redirect && this.homeUrl) {
            if (this.destinationUrl) {
              this.router
                .navigate([this.destinationUrl.split('?')[0]], {
                  queryParams: this.extractQueryParams(this.destinationUrl),
                })
                .then();
            } else {
              const extras = {};
              this.router.navigate(['/', this.homeUrl], extras).then();
            }
            this.destinationUrl = null;
          }
        },
        error: (_error) => {
          denied(_error.error);
          if (redirect) {
            this.router.navigate([AppRoutes.Root.login]).then();
          }
          throwError(() => _error?.error || _error);
        },
      });
  }

  loginWithSSOIdToken(
    idToken: string,
    redirect: boolean,
    granted: Function,
    denied: Function,
  ) {
    const params = new HttpParams();
    return this.http
      .post(
        env.api + '/login-sso-token',
        { idToken: idToken },
        { params: params },
      )
      .pipe(
        map((_response: any) => {
          this.token = _response.data['token'];
          this.operator = _response.data['user'];
          if (this.operator) {
            this._currentUserId = this.operator.id;
            localStorage.setItem('user', JSON.stringify(this.operator));
          }

          let headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'application/json');
          this.auth_headers = headers.append(
            'Authorization',
            'Bearer ' + this.token,
          );

          if (this.token) {
            this.registerToken(this.token.toString());
          }

          this.isAuthenticatedSubject.next(true);
          const user = this.getUser();
          this.userSubject.next(user);

          this.registerUserCookie(user);

          granted(this.operator);
          return this.operator;
        }),
      )
      .subscribe({
        next: async () => {
          await lastValueFrom(this.loadUser());

          if (redirect && this.homeUrl) {
            if (this.destinationUrl) {
              this.router
                .navigate([this.destinationUrl.split('?')[0]], {
                  queryParams: this.extractQueryParams(this.destinationUrl),
                })
                .then();
            } else {
              const extras = {};
              this.router.navigate(['/', this.homeUrl], extras).then();
            }
            this.destinationUrl = null;
          }
        },
        error: (_error) => {
          denied(_error.error);
          if (redirect) {
            this.router.navigate([AppRoutes.Root.login]).then();
          }
          throwError(() => _error?.error || _error);
        },
      });
  }

  logout(doNotDeleteDestinationUrl?: boolean, isAccountDeletion = false) {
    this.removeToken();

    if (isAccountDeletion) {
      this.router.navigate(['']).then();
    } else {
      this.router.navigate([AppRoutes.Root.login]).then();
    }

    if (!doNotDeleteDestinationUrl) {
      this.destinationUrl = null;
    }
  }

  removeToken() {
    localStorage.removeItem(this.localStorageKeyUser);

    this.cookieService.delete(this.cookieServiceKey, '/');
    this.cookieService.delete(this.eddyUserCookieServiceKey, '/');
    this.operator = null;
    this.auth_headers = null;
    this.token = null;
    this.isAuthenticatedSubject.next(false);
    this.userSubject.next(this.getUser());
  }

  verifyUserViaCode(code: string) {
    return this.http
      .post<any>(`${env.api}/verify/${code}`, {}, this.getHttpOptions())
      .pipe(
        map((res: any) => {
          this.markLocalUserAsVerified();
          return res['data'];
        }),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  verifyUserViaHash(hash: string) {
    return this.http
      .post<any>(`${env.api}/verify/hash/${hash}`, {}, this.getHttpOptions())
      .pipe(
        map((res: any) => {
          this.markLocalUserAsVerified();
          return res['data'];
        }),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  resendVerificationCode(userId: number, type: UserVerificationCodeType) {
    return this.http
      .post<any>(
        `${env.api}/verify/resend`,
        { userId, type },
        this.getHttpOptions(),
      )
      .pipe(
        map((res: any) => res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  forgotPassword(value: PasswordForgotRequest) {
    return this.http
      .post<any>(
        `${env.api}/users/password/forgot`,
        value,
        this.getHttpOptions(),
      )
      .pipe(
        map((res: any) => res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  resetPassword(request: PasswordResetRequest) {
    return this.http
      .post<any>(
        `${env.api}/users/password/reset`,
        request,
        this.getHttpOptions(),
      )
      .pipe(
        map((res: any) => res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  resendVerificationCodeForEmail(request: ResendEmailCodeRequest) {
    return this.http
      .post<any>(
        `${env.api}/verify/resend/email`,
        request,
        this.getHttpOptions(),
      )
      .pipe(
        map((res: any) => res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  updateUser(req: UpdateUserRequest) {
    return this.http
      .put<User>(`${env.api}/users`, req, this.getHttpOptions())
      .pipe(
        map((res: any) => res['data']),
        tap((res) => {
          localStorage.setItem('user', JSON.stringify(res));
          this.userSubject.next(this.getUser());
        }),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  updatePassword(userId: number, currentPassword: string, newPassword: string) {
    const body = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    return this.http
      .put(`${env.api}/users/${userId}/password`, body, this.getHttpOptions())
      .pipe(
        map((res: any) => res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  setPhone(userId: number, phone: string) {
    return this.http
      .put(`${env.api}/users/${userId}/phone`, { phone }, this.getHttpOptions())
      .pipe(
        map((res: any) => res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  userOrAccountWithEmailExists(
    email: string,
    params?: Map<string, any>,
  ): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${env.api}/users/email/${email}/exists`,
        this.getHttpOptions(params),
      )
      .pipe(
        map((res: any) => !!res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  userOrAccountWithCredentialsExists(req: LoginRequest): Observable<any> {
    return this.http
      .post<any>(
        `${env.api}/users/credentials/exists`,
        req,
        this.getHttpOptions(),
      )
      .pipe(
        map((res: any) => res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  userOrAccountWithPhoneExists(phone: string): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${env.api}/users/phone/${phone}/exists`,
        this.getHttpOptions(),
      )
      .pipe(
        map((res: any) => !!res['data']),
        catchError((err) => throwError(() => err?.error || err)),
      );
  }

  userHasAnyRole(neededRoles: UserRole[], userRole: UserRole) {
    return neededRoles.indexOf(userRole) !== -1;
  }

  private markLocalUserAsVerified() {
    const user = this.getUser();
    if (!user) {
      return;
    }

    user.emailVerified = true;

    localStorage.setItem(this.localStorageKeyUser, JSON.stringify(user));
    this.userSubject.next(user);
  }

  private loadUser(): Observable<User> {
    return this.http
      .get<User>(
        `${env.api}/users/${this.currentUserId}`,
        this.getHttpOptions(),
      )
      .pipe(
        map((it: any) => {
          try {
            return new User(it['data']);
          } catch (err) {
            throw err;
          }
        }),
        map((user) => {
          try {
            this.operator = user;
            if (this.operator) {
              localStorage.setItem(
                this.localStorageKeyUser,
                JSON.stringify(this.operator),
              );
              this.registerUserCookie(this.operator);

              this.userSubject.next(this.operator);

              return user;
            }
            return this.operator;
          } catch (err) {
            throw err;
          }
        }),
        catchError((err: any) => {
          return throwError(() => err?.error || err);
        }),
      );
  }

  areCookiesAllowed(): boolean {
    return true;
  }

  get homeUrl(): string {
    const user = this.getUser();

    if (isAdmin(user.role)) {
      return env.landingAdmin;
    } else if (isCustomer(user.role)) {
      return env.landingCustomer;
    }

    return '';
  }

  extractQueryParams(url: string): Params {
    if (!url.includes('?')) {
      return {};
    }
    const params = url.split('?')[1];
    const queryParams = params.split('&');
    const queryParamsObj: { [key: string]: string } = {};
    queryParams.forEach((qParam) => {
      queryParamsObj[qParam.split('=')[0]] = qParam.split('=')[1];
    });

    return queryParamsObj;
  }
}
