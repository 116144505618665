import { ClassData } from '@models/class-data';
import { StripeSubscriptionStatus } from './stripe-subscription-status.enum';
import { NumberUtils } from '@utils/number-utils';
import moment from 'moment';
import { OneTimePricingPlan } from './one-time-pricing-plan.model';

export class UserOneTimePricingPlan {
  id: number;

  userId: number;

  oneTimePricingPlanId: number;
  oneTimePricingPlan?: OneTimePricingPlan;

  isPaid: boolean;
  isProcessing: boolean;

  stripeHostedInvoiceUrl?: string;

  planEndDate?: Date;

  constructor(json: ClassData<UserOneTimePricingPlan>) {
    this.id = +json.id;

    this.userId = +json.userId;

    this.oneTimePricingPlanId = +json.oneTimePricingPlanId;
    if (json.oneTimePricingPlan) {
      this.oneTimePricingPlan = new OneTimePricingPlan(json.oneTimePricingPlan);
    }

    this.isPaid = json.isPaid;
    this.isProcessing = json.isProcessing;

    this.stripeHostedInvoiceUrl = json.stripeHostedInvoiceUrl;

    if (json.planEndDate) {
      this.planEndDate = new Date(json.planEndDate);
    }
  }

  isActive(): boolean {
    return this.isPaid && !this.isExpired();
  }

  isExpired(): boolean {
    return !!this.planEndDate && moment(this.planEndDate).isBefore(moment());
  }

  isPlanProcessing(): boolean {
    return !this.isPaid && this.isProcessing;
  }

  getPriceFormatted(): string {
    if (!this.oneTimePricingPlan) {
      return '-';
    }

    return `${NumberUtils.roundToUpTo2Decimals(
      this.oneTimePricingPlan?.priceInCents / 100.0,
    )} €`;
  }
}
