import { ClassData } from '@models/class-data';
import { UserIntegrationType } from '@models/users/user-integration-type.enum';

export class UserIntegration {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  userId: number;
  type: UserIntegrationType;
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: number;

  constructor(json: ClassData<UserIntegration>) {
    this.id = json.id;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.userId = json.userId;
    this.type = json.type;
    this.accessToken = json.accessToken;
    this.refreshToken = json.refreshToken;
    this.expiresIn = json.expiresIn;
  }
}
