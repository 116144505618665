import { ClassData } from '@models/class-data';
import { PricingPlanType } from './pricing-plan-type.enum';
import { NumberUtils } from '@utils/number-utils';

export class OneTimePricingPlan {
  id: number;

  name: string;
  description: string;

  type: PricingPlanType;

  priceInCents: number;

  order?: number;

  durationDays?: number;

  stripeProductId: string;
  stripePriceId: string;

  constructor(json: ClassData<OneTimePricingPlan>) {
    this.id = +json.id;

    this.name = json.name;
    this.description = json.description;

    this.type = json.type;

    this.priceInCents = +json.priceInCents;

    if (json.order !== undefined) {
      this.order = +json.order;
    }

    if (json.durationDays !== undefined) {
      this.durationDays = +json.durationDays;
    }

    this.stripeProductId = json.stripeProductId;
    this.stripePriceId = json.stripePriceId;
  }

  getPriceFormatted(): string {
    if (!this.priceInCents) {
      return '0€';
    }

    return `${NumberUtils.roundToUpTo2Decimals(
      this.priceInCents / 100.0,
    ).replace(/[.,]00$/, '')}€`;
  }

  getItems(): string[] {
    if (this.type === PricingPlanType.WP_LOVELY_MEMORIES) {
      return [
        'APP.ONE_TIME_PRICING_PLAN.WP_LOVELY_MEMORIES.RSVP_FUNCTION',
        'APP.ONE_TIME_PRICING_PLAN.WP_LOVELY_MEMORIES.TODO_LIST',
        'APP.ONE_TIME_PRICING_PLAN.WP_LOVELY_MEMORIES.FINANCIAL_PLANNER',
      ];
    } else if (this.type === PricingPlanType.WP_FOREVER_YOURS) {
      return [
        'APP.ONE_TIME_PRICING_PLAN.WP_FOREVER_YOURS.STD_AND_THANK_YOU_CARD',
      ];
    }

    return [
      'APP.ONE_TIME_PRICING_PLAN.WP_BASIC_LOVE.DESIGN_TEMPLATES',
      'APP.ONE_TIME_PRICING_PLAN.WP_BASIC_LOVE.COUNTDOWN',
      'APP.ONE_TIME_PRICING_PLAN.WP_BASIC_LOVE.GALLERY',
      'APP.ONE_TIME_PRICING_PLAN.WP_BASIC_LOVE.TIMETABLE',
      'APP.ONE_TIME_PRICING_PLAN.WP_BASIC_LOVE.LOCATION',
      'APP.ONE_TIME_PRICING_PLAN.WP_BASIC_LOVE.WISHLIST',
      'APP.ONE_TIME_PRICING_PLAN.WP_BASIC_LOVE.FAQ',
    ];
  }

  getBgColor(): string {
    if (this.type === PricingPlanType.WP_LOVELY_MEMORIES) {
      return '#FFDD8F';
    } else if (this.type === PricingPlanType.WP_FOREVER_YOURS) {
      return '#E4C1F9';
    }

    return 'white';
  }

  getDurationInYears(): number {
    if (!this.durationDays) {
      return 0;
    }

    return this.durationDays / 365;
  }
}
