import { ClassData } from '@models/class-data';
import { CommunityMemberAdditionalData } from '@models/communities/community-member-additional-data.model';
import { CommunityMemberStatus } from '@models/communities/community-member-status.enum';
import { Community } from '@models/communities/community.model';
import { User } from '@models/users/user.model';

export class CommunityMember {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  communityId: number;
  userId: number;
  user?: User;
  community?: Community;
  status?: CommunityMemberStatus;
  additionalData?: CommunityMemberAdditionalData[];

  constructor(json: ClassData<CommunityMember>) {
    this.id = json.id;
    this.createdAt = new Date(json.createdAt);
    this.updatedAt = new Date(json.updatedAt);
    this.communityId = json.communityId;
    this.userId = json.userId;
    if (json.user) {
      this.user = new User(json.user);
    }

    if (json.community) {
      this.community = new Community(json.community);
    }
    this.status = json.status;

    if (json.additionalData) {
      this.additionalData = json.additionalData.map(
        (item) => new CommunityMemberAdditionalData(item),
      );
    }
  }
}
