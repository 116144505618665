export enum PricingPlanType {
  PRO = 'PRO',
  TEAMS = 'TEAMS',
  WP_BASIC_LOVE = 'WP_BASIC_LOVE',
  WP_LOVELY_MEMORIES = 'WP_LOVELY_MEMORIES',
  WP_FOREVER_YOURS = 'WP_FOREVER_YOURS',
}

export namespace PricingPlanType {
  export function getDisplayName(pricingPlanType?: PricingPlanType): string {
    switch (pricingPlanType) {
      case PricingPlanType.PRO:
        return 'Pro';
      case PricingPlanType.TEAMS:
        return 'Teams';
      default:
        return '-';
    }
  }
}
